<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed } from 'vue';

import { type UserServiceHistoryItem } from '@/types/user-services-history-item';

const { greaterOrEqual } = useBreakpoints(breakpointsTailwind);
const mdOrLarger = greaterOrEqual('md');

const props = defineProps<{
  item: UserServiceHistoryItem;
}>();

const requestDateAsDate = computed(() => new Date(props.item.requestedAt));
const cleanOriginAddress = computed(() => props.item.originAddress?.trim());
const cleanDestinationAddress = computed(() => props.item.destinationAddress?.trim());
</script>

<template>
  <div
    class="flex flex-col gap-4 rounded-lg bg-gray-100 px-4 py-6 md:p-9"
  >
    <p class="text-sm font-semibold md:text-lg">
      {{ $t('serviceHistory.requestDate') }} <br class="md:hidden"> {{ $d(requestDateAsDate, 'long') }}
    </p>
    <div class="flex flex-col gap-4 md:flex-row md:items-start md:gap-6">
      <base-image
        v-if="item.service.imageUrl"
        class="shrink-0"
        img-styles="aspect-[280/120] w-full overflow-hidden rounded-lg object-cover md:aspect-[240/144] md:max-w-72"
        :src="item.service.resizedImageUrl || item.service.imageUrl"
        :webp-src="item.service.resizedWebpImageUrl"
        :blurhash="item.service.imageBlurhash"
        :alt="$t('services.fields.imageAltText', { name: item.service.name })"
      />
      <div class="flex w-full flex-col items-start gap-2">
        <base-mok-tag
          :label="$t(`serviceHistory.statuses.${item.currentStatus}.name`)"
          :color="item.currentStatus === 'cancelled' ? 'gray' : 'primary'"
          variant="secondary"
          size="medium"
        />
        <h2 class="text-lg font-semibold md:text-3xl">
          {{ item.service.name }}
        </h2>
        <p
          v-if="cleanOriginAddress"
          class="text-sm text-gray-500 md:text-base"
        >
          {{ $t('serviceHistory.originAddress') }}: {{ cleanOriginAddress }}
          <template v-if="cleanDestinationAddress">
            <br>
            {{ $t('serviceHistory.destinationAddress') }}: {{ cleanDestinationAddress }}
          </template>
        </p>
      </div>
      <mok-button
        class="shrink-0 self-center md:ml-auto"
        :label="$t('serviceHistory.openDetailButton')"
        :href="`/user/services_history/${item.id}`"
        :full-width="!mdOrLarger"
      />
    </div>
  </div>
</template>
