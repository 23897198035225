<script setup lang="ts">
import { camelize } from 'humps';
import { computed, inject, ref, type Ref } from 'vue';

import eventsApi from '@/api/events';
import useSiteVariables from '@/composables/useSiteVariables';
import type { Service } from '@/types/service';
import formatNumber from '@/utils/format-number';
import { classesByRedemptionKind, iconSrcByRedemptionKind } from '@/utils/service-redemption-kind-maps';

import LoyaltyRedemptionConfirmationModal from './loyalty-redemption-confirmation-modal.vue';
import LoyaltyRedemptionErrorModal from './loyalty-redemption-error-modal.vue';
import LoyaltyRedemptionSuccessModal from './loyalty-redemption-success-modal.vue';

interface Props {
  service: Service,
}
type ToggleServiceLoginRedirectType = () => void;

const props = defineProps<Props>();

const points = inject<Ref<number | undefined>>('points');
const units = inject<string | undefined>('units');
const toggleServiceLoginRedirect = inject('toggleServiceLoginRedirect') as ToggleServiceLoginRedirectType;
const { isLoginRequired } = useSiteVariables();

const availableToBuy = computed(() => {
  if (!points?.value) return false;

  return props.service.pointCost <= points.value;
});

const isDisabled = computed(() => (
  !availableToBuy.value && !isLoginRequired
));

const showRedemptionConfirmation = ref(false);
const showRedemptionSuccess = ref(false);
const showRedemptionError = ref(false);

function toggleRedemptionConfirmation() {
  showRedemptionConfirmation.value = !showRedemptionConfirmation.value;
}
function toggleRedemptionSuccess() {
  showRedemptionSuccess.value = !showRedemptionSuccess.value;
}
function toggleRedemptionError() {
  showRedemptionError.value = !showRedemptionError.value;
}

function clickButton() {
  eventsApi.send('service_select', props.service.id);
  if (isLoginRequired) {
    toggleServiceLoginRedirect();
  } else if (availableToBuy.value) {
    toggleRedemptionConfirmation();
  }
}

const redemptionKind = computed(() => (
  camelize(props.service.redemptionKind)
));
const isUnlimitedAccess = computed(() => redemptionKind.value === 'unlimitedAccess');
const isUnlimitedAccessAndHasRedemption = computed(() => isUnlimitedAccess.value && !!props.service.lastRedemption);
const isSingleUse = computed(() => redemptionKind.value === 'singleUse');
const isSingleUseAndHasUnusedRedemption = computed(
  () => isSingleUse.value && !!props.service.lastRedemption && !props.service.lastRedemption.usedAt,
);
const hasAccess = computed(() => isUnlimitedAccessAndHasRedemption.value || isSingleUseAndHasUnusedRedemption.value);
</script>
<template>
  <base-card
    :image-url="service.resizedImageUrl || service.imageUrl"
    :resized-webp-image-url="service.resizedWebpImageUrl"
    :image-blurhash="service.imageBlurhash"
    :title="service.name"
    title-size="small"
    :description="service.description || $t('loyalty.redeemableContentCard.description')"
    unlimited-description-height
  >
    <template #image-decoration>
      <div class="absolute top-4 rounded-r-full bg-white text-sm">
        <div
          class="flex items-center rounded-r-full px-2 py-px"
          :class="[classesByRedemptionKind(redemptionKind)]"
        >
          <base-svg
            :name="iconSrcByRedemptionKind(redemptionKind)"
            class="mr-0.5 h-4"
          />
          {{ $t(`loyalty.shared.redemptionKinds.${redemptionKind}`) }}
        </div>
      </div>
    </template>
    <template #header>
      <div
        class="my-3 ml-4 flex w-fit rounded-full bg-primary px-4 py-1.5 text-sm text-white"
        :class="{
          'bg-slate-400': isDisabled,
          'bg-success-600': hasAccess,
        }"
      >
        <template v-if="hasAccess">
          <base-svg
            name="check-circle"
            class="mr-2 size-5 fill-current"
          />
          {{ $t('loyalty.redeemableContentCard.redeemed') }}
        </template>
        <template v-else>
          {{
            $t(
              'loyalty.redeemableContentCard.pointCost', {pointCost: formatNumber(service.pointCost), units: units}
            )
          }}
        </template>
      </div>
    </template>
    <template #call-to-action>
      <base-service-select
        is="base-button"
        v-if="hasAccess"
        :service="service"
        theme="primary"
        :label="$t('loyalty.redeemableContentCard.access')"
        class="w-full text-sm"
        skip-event-send
        skip-login-check
      />
      <div
        v-else-if="isDisabled"
        class="mb-2 px-4 py-2 text-sm font-bold text-slate-400"
      >
        {{ $t('loyalty.redeemableContentCard.insufficientPoints') }}
      </div>
      <base-link
        v-else
        theme="primary"
        class="mb-2 max-w-max text-sm font-bold underline"
        :label="$t('loyalty.redeemableContentCard.callToAction')"
        @click="clickButton"
      />
    </template>
  </base-card>
  <loyalty-redemption-confirmation-modal
    :open="showRedemptionConfirmation"
    :service="service"
    :points="points ? points : 0"
    :units="units ? units : $t('loyalty.shared.pointUnits')"
    @close="toggleRedemptionConfirmation"
    @open-success="toggleRedemptionSuccess"
    @open-error="toggleRedemptionError"
  />
  <loyalty-redemption-success-modal
    :open="showRedemptionSuccess"
    :service="service"
    @close="toggleRedemptionSuccess"
  />
  <loyalty-redemption-error-modal
    :open="showRedemptionError"
    @close="toggleRedemptionError"
  />
</template>
