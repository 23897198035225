<script setup lang="ts">
import '@vuepic/vue-datepicker/dist/main.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import { computed, ref } from 'vue';

const TIME_ZERO_PADDING = 2;
const MIN_TIME_HOURS = 9;
const MIN_TIME_MINUTES = 0;
const MAX_TIME_HOURS = 17;
const MAX_TIME_MINUTES = 30;

interface Props {
  modelValue?: string;
  label: string;
  name: string;
  size?: 'xs' | 'sm';
  weight?: 'bold' | 'semibold' | 'normal';
  description?: string;
  optional?: boolean;
  disabled?: boolean;
  placeholder?: string;
  initialValue?: string;
  minTime?: { hours: number, minutes: number };
}

interface Time {
  hours: number,
  minutes: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  size: 'sm',
  weight: 'normal',
  description: undefined,
  optional: false,
  disabled: false,
  placeholder: undefined,
  initialValue: undefined,
  minTime: () => ({ hours: MIN_TIME_HOURS, minutes: MIN_TIME_MINUTES }),
});

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();

function parseTimeString(timeStr: string) {
  const [hours, minutes] = timeStr.split(':');

  return {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  };
}

const startTime = computed(() => {
  if (props.modelValue) {
    return parseTimeString(props.modelValue);
  }

  const defaultTime = {
    hours: props.minTime.hours,
    minutes: props.minTime.minutes,
  };

  if (!props.initialValue) {
    return defaultTime;
  }

  const parsedTime = parseTimeString(props.initialValue);

  const isBeforeMinTime = parsedTime.hours < props.minTime.hours ||
    (parsedTime.hours === props.minTime.hours && parsedTime.minutes < props.minTime.minutes);

  return isBeforeMinTime ? defaultTime : parsedTime;
});

const time = ref<Time>(startTime.value);

function handleChange(value: Time) {
  const formattedMinutes = value.minutes.toString().padStart(TIME_ZERO_PADDING, '0');
  const formattedHours = value.hours.toString().padStart(TIME_ZERO_PADDING, '0');
  const formattedTime = `${formattedHours}:${formattedMinutes}`;
  time.value = value;
  emit('update:modelValue', formattedTime);
}

defineOptions({
  inheritAttrs: false,
});
</script>
<template>
  <vue-date-picker
    data-testid="vue-date-picker"
    :uid="name"
    :select-text="$t('common.select')"
    :cancel-text="$t('common.cancel')"
    :disabled="disabled"
    :minutes-increment="30"
    :minutes-grid-increment="30"
    :min-time="minTime"
    :max-time="{ hours: MAX_TIME_HOURS, minutes: MAX_TIME_MINUTES }"
    :model-value="time"
    time-picker
    @update:model-value="handleChange"
  >
    <template #dp-input="{ value }">
      <base-input
        is="input"
        :label="label"
        :name="name"
        type="text"
        :model-value="value"
        :class="$attrs.class"
        :disabled="disabled"
        :optional="optional"
        :placeholder="placeholder"
        :size="size"
        :weight="weight"
        v-bind="$attrs"
      />
    </template>
    <template #action-preview />
    <template #clear-icon="{ clear }">
      <base-svg
        class="mr-2 mt-5 size-3 fill-current"
        name="close"
        @click="clear"
      />
    </template>
  </vue-date-picker>
</template>
