import moment from 'moment';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const ONE_MINUTE_IN_MS = 60000;
const THIRTY_MINUTES = 30;
const SIXTY_MINUTES = 60;
const ZERO_SECONDS = 0;
const LAST_VALID_TIME_HOURS = 17;
const LAST_VALID_TIME_MINUTES = 30;
const NEXT_DAY_FIRST_VALID_TIME_HOURS = 9;

function getNextTimeSlot(currentTime: moment.Moment) {
  const nextTime = currentTime.clone().add(1, 'hour');
  nextTime.minutes(currentTime.minutes() > THIRTY_MINUTES ? SIXTY_MINUTES : THIRTY_MINUTES);

  return nextTime.seconds(ZERO_SECONDS);
}

export default function useSameDayServiceScheduling() {
  const currentMoment = ref(moment());

  const nextValidTime = computed(() => {
    const now = currentMoment.value.clone();
    const lastValidTime = now.clone().hours(LAST_VALID_TIME_HOURS).minutes(LAST_VALID_TIME_MINUTES);
    const nextTime = getNextTimeSlot(now);

    if (nextTime.isAfter(lastValidTime)) {
      const tomorrow = now.clone().add(1, 'day').hours(NEXT_DAY_FIRST_VALID_TIME_HOURS)
        .minutes(0);

      return tomorrow;
    }

    return nextTime;
  });

  let interval: ReturnType<typeof setInterval>;

  onMounted(() => {
    interval = setInterval(() => {
      currentMoment.value = moment();
    }, ONE_MINUTE_IN_MS);
  });

  onBeforeUnmount(() => {
    clearInterval(interval);
  });

  return { nextValidTime };
}
