<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhPhone, PhHouse, PhEnvelope, PhInstagramLogo } from '@phosphor-icons/vue';
import { computed } from 'vue';

import LegalDocumentsLink from '@/components/legal-documents-links.vue';
import { Color } from '@/types/color';
import type { FifthFooter } from '@/types/fifth-footer';
import type { LegalDocument } from '@/types/legal-document';
import FacebookIcon from '@assets/images/facebook.svg';

interface Props {
  fifthFooter: FifthFooter;
  legalDocuments: LegalDocument[];
  copyrightText: string;
  copyrightYear: string;
}

const colorStyles = {
  primary: {
    bg: 'bg-primary',
    text: 'text-primary',
    iconVariant: 'primary',
  },
  secondary: {
    bg: 'bg-secondary',
    text: 'text-secondary',
    iconVariant: 'secondary',
  },
  black: {
    bg: 'bg-black',
    text: 'text-black',
    iconVariant: 'gray',
  },
  white: {
    bg: 'bg-white',
    text: 'text-white',
    iconVariant: 'white',
  },
};

const props = withDefaults(
  defineProps<Props>(),
  {
    legalDocuments: () => [] as LegalDocument[],
    copyrightText: '',
    copyrightYear: '',
  },
);

const extendedBgColorVariant = computed(() => props.fifthFooter.extendedBgColor || 'white');
const textColorVariant = computed(() => props.fifthFooter.textColor || 'black');
const iconColorVariant = computed(() => props.fifthFooter.iconColor || 'primary');
const extendedBgColorClass = computed(() => colorStyles[extendedBgColorVariant.value].bg);
const textColorClass = computed(() => colorStyles[textColorVariant.value].text);
const iconColorClass = computed(() =>
  colorStyles[iconColorVariant.value].iconVariant as Color);

const logos = computed(() => {
  const validLogos = [
    { url: props.fifthFooter.firstLogoUrl, webpUrl: props.fifthFooter.firstLogoWebpUrl,
      blurhash: props.fifthFooter.firstLogoBlurhash },
    { url: props.fifthFooter.secondLogoUrl, webpUrl: props.fifthFooter.secondLogoWebpUrl,
      blurhash: props.fifthFooter.secondLogoBlurhash },
    { url: props.fifthFooter.thirdLogoUrl, webpUrl: props.fifthFooter.thirdLogoWebpUrl,
      blurhash: props.fifthFooter.thirdLogoBlurhash },
    { url: props.fifthFooter.fourthLogoUrl, webpUrl: props.fifthFooter.fourthLogoWebpUrl,
      blurhash: props.fifthFooter.fourthLogoBlurhash },
    { url: props.fifthFooter.fifthLogoUrl, webpUrl: props.fifthFooter.fifthLogoWebpUrl,
      blurhash: props.fifthFooter.fifthLogoBlurhash },
  ].filter(logo => logo.url);

  return validLogos;
});
const hasValidLogos = computed(() => logos.value.some(logo => logo.url));

const socialButtons = computed(() => [
  {
    url: props.fifthFooter.sponsorHomeLink,
    icon: PhHouse,
    condition: !!props.fifthFooter.sponsorHomeLink,
  },
  {
    url: `mailto:${props.fifthFooter.contactEmail}`,
    icon: PhEnvelope,
    condition: !!props.fifthFooter.contactEmail,
  },
  {
    url: props.fifthFooter.facebookLink,
    icon: FacebookIcon,
    condition: !!props.fifthFooter.facebookLink,
  },
  {
    url: props.fifthFooter.instagramLink,
    icon: PhInstagramLogo,
    condition: !!props.fifthFooter.instagramLink,
  },
].filter(button => button.condition));

</script>

<template>
  <div
    class="w-full"
    :class="[extendedBgColorClass, textColorClass]"
  >
    <div class="mx-auto flex w-full flex-col px-6 py-4 sm:flex-row sm:gap-0 sm:px-20 sm:py-8 md:max-w-screen-2xl">
      <div class="hidden sm:flex sm:w-20 sm:items-center sm:justify-center">
        <div class="-rotate-90">
          <base-image
            v-if="fifthFooter.regulatoryComplianceImageUrl"
            class="h-14 w-64 justify-end self-end"
            :src="fifthFooter.regulatoryComplianceImageUrl"
            :webp-src="fifthFooter.regulatoryComplianceWebpImageUrl"
            :blurhash="fifthFooter.regulatoryComplianceImageBlurhash"
            img-styles="object-contain"
          />
        </div>
      </div>
      <div class="flex w-full flex-col items-center justify-center sm:gap-0">
        <div class="flex flex-row flex-wrap justify-center gap-6 sm:mb-6 sm:items-start sm:justify-start sm:gap-6 sm:gap-x-10">
          <img
            v-if="!hasValidLogos"
            class="h-4 w-full max-w-20 overflow-hidden object-contain sm:h-8"
            :alt="$t('common.logo')"
            :src="props.fifthFooter.darkBgLogoUrl"
          >
          <template
            v-for="(logo, index) in logos"
            v-else
            :key="logo.url"
          >
            <base-image
              v-if="logo.url"
              class="h-10 max-w-fit overflow-hidden object-contain sm:h-16"
              img-styles="object-contain"
              :src="logo.url"
              :webp-src="logo.webpUrl"
              :blurhash="logo.blurhash"
              size="small sm:medium"
            />
            <div
              v-if="index < logos.length - 1"
              class="hidden h-16 w-0.5 border-l border-gray-200 sm:block"
            />
          </template>
        </div>
        <div
          v-if="fifthFooter.contactPhone"
          class="flex flex-col pb-4 pt-8"
        >
          <p class="hidden shrink-0 flex-row text-xs sm:block">
            {{ $t('sections.footers.secondFooter.contactPhoneText') }}
            <span class="font-semibold">{{ fifthFooter.contactPhone }}</span>
          </p>
          <div class="flex flex-row justify-center sm:hidden sm:items-center">
            <PhPhone
              weight="fill"
              class="mr-1 flex size-4 fill-current sm:mr-4 sm:size-8 sm:items-center"
              :class="[textColorClass]"
            />
            <div class="flex flex-col items-center text-xs font-medium sm:font-bold">
              <p>
                {{ fifthFooter.contactPhone }}
              </p>
            </div>
          </div>
        </div>
        <div class="mr-0 flex w-max flex-col items-center justify-center pb-7 sm:pb-14">
          <div class="mt-auto flex flex-row gap-3">
            <mok-button
              v-for="(button) in socialButtons"
              :key="button.url"
              :href="button.url"
              :color="iconColorClass"
              :icon="button.icon"
              size="medium"
              rounded-full
              target="_blank"
            />
          </div>
        </div>
        <div class="hidden sm:block">
          <legal-documents-link
            :legal-documents="legalDocuments"
            :copyright-text="copyrightText"
            :copyright-year="copyrightYear"
            :text-color-class="textColorClass"
            class="flex min-w-0 flex-wrap gap-x-6 text-xs"
            data-testid="legal-documents-link"
          />
        </div>
      </div>
      <div
        class="flex flex-row sm:hidden"
      >
        <div class="w-full">
          <legal-documents-link
            :legal-documents="legalDocuments"
            :copyright-text="copyrightText"
            :copyright-year="copyrightYear"
            :text-color-class="textColorClass"
            class="flex flex-col text-xs"
            data-testid="legal-documents-link"
          />
        </div>
        <div
          class="flex w-full justify-end self-end text-base"
        >
          <div class="flex flex-col">
            <base-image
              v-if="fifthFooter.regulatoryComplianceImageUrl"
              class="mt-4 h-5 max-w-sm justify-end self-end overflow-hidden object-contain"
              :src="fifthFooter.regulatoryComplianceImageUrl"
              :webp-src="fifthFooter.regulatoryComplianceWebpImageUrl"
              :blurhash="fifthFooter.regulatoryComplianceImageBlurhash"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
