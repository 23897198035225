<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import { useMutation } from 'vue-query';

import eventsApi from '@/api/events';
import serviceApi from '@/api/service';
import useSiteVariables from '@/composables/useSiteVariables';
import type { Service, ServiceWithFamilyInfo } from '@/types/service';

type ToggleServiceLoginRedirectType = () => void;

interface Props {
  service: Service | ServiceWithFamilyInfo;
  skipEventSend?: boolean;
  skipLoginCheck?: boolean;
  theme: 'primary' | 'secondary' | 'white';
  label: string;
  is: 'base-button' | 'base-link';
}

const props = withDefaults(defineProps<Props>(), {
  skipEventSend: false,
  skipLoginCheck: false,
});

const isAuthenticatedRedirectService = computed(() => props.service.kind === 'authenticated_redirect');
const isPostHTTPMethod = computed(() => props.service.httpMethod === 'POST');
const isHttpMethodMissing = computed(() => !props.service.httpMethod);

const showPreRedirectModalError = ref(false);

const toggleServiceLoginRedirect = inject('toggleServiceLoginRedirect') as ToggleServiceLoginRedirectType;

const { isLoginRequired } = useSiteVariables();

function closePreRedirectModalError() {
  showPreRedirectModalError.value = false;
}

function redirectUrlWithServiceId(redirectUrl: string) {
  const url = new URL(redirectUrl);
  url.searchParams.append('service_id', props.service.mokServicesId.toString());

  return url.toString();
}

function redirectService(redirectUrl: string) {
  if (isAuthenticatedRedirectService.value &&
  (isPostHTTPMethod.value || isHttpMethodMissing.value)) {
    const form: HTMLFormElement = document.getElementById('service-form') as HTMLFormElement;
    form.action = isPostHTTPMethod.value ? redirectUrlWithServiceId(redirectUrl) : redirectUrl;
    form.submit();

    return;
  }

  window.location.href = redirectUrl;
}

const {
  mutate: preRedirectCallbackMutation,
} = useMutation(
  () => serviceApi.preRedirectCallback(props.service.id), {
    onSuccess: (response) => redirectService(response.data.redirectUrl),
    onError: () => {
      showPreRedirectModalError.value = true;
    },
  },
);

function selectService(event: Event) {
  const { origin, pathname } = window.location;
  sessionStorage.setItem('referrerUrl', origin + pathname);
  if (!props.skipEventSend) eventsApi.send('service_select', props.service.id);
  if (!props.skipLoginCheck && isLoginRequired) {
    event.preventDefault();
    toggleServiceLoginRedirect();

    return;
  }
  if (props.service.preRedirectCallbackPath) {
    event.preventDefault();
    preRedirectCallbackMutation();
  }
}

const buttonType = computed(() => (!props.skipLoginCheck && isLoginRequired ? 'button' : 'submit'));
const themes = {
  'base-button': {
    primary: 'primary-button',
    secondary: 'secondary-button',
    white: undefined,
  },
  'base-link': {
    primary: 'primary',
    secondary: 'secondary',
    white: 'white',
  },
};

const currentTheme = computed(() => themes[props.is][props.theme]);

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <form
    v-if="isAuthenticatedRedirectService && (isPostHTTPMethod || isHttpMethodMissing)"
    id="service-form"
    :action="service.url"
    method="post"
    :target="service.target"
  >
    <component
      :is="is"
      :type="buttonType"
      v-bind="$attrs"
      :theme="currentTheme"
      :label="label"
      @click="selectService"
    />
  </form>
  <component
    :is="is"
    v-else
    :href="service.url"
    :target="service.target"
    :label="label"
    v-bind="$attrs"
    :theme="currentTheme"
    @click="selectService"
  />

  <base-modal
    :open="showPreRedirectModalError"
    position-option="mixed"
    custom-width="w-full md:w-3/5 md:w-fit md:min-w-96 md:max-w-4xl"
    @close="closePreRedirectModalError"
  >
    <div class="p-6">
      <h2 class="text-lg font-bold">
        {{ $t('serviceSelect.preRedirectModalError.title') }}
      </h2>
      <p class="text-center">
        {{ $t('serviceSelect.preRedirectModalError.description') }}
      </p>
    </div>
  </base-modal>
</template>
