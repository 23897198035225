<script setup lang="ts">
import { MokAlertModal, MokButton } from '@mok-labs/components';
import { PhPhoneCall } from '@phosphor-icons/vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Values } from '@/api/service';
import useSiteVariables from '@/composables/useSiteVariables';
import type { Service } from '@/types/service';

type AddressValues = Values & {
  route: string
}

type GroupedDataEntry = {
  inputName: string;
  value: string;
  order: number;
};

type GroupedData = Record<string, GroupedDataEntry[]>;

export type Props = {
  service: Service;
  formValues: Values;
  otId?: number;
};

const props = defineProps<Props>();

const { t } = useI18n({});

const { featureFlags: { serviceHistory, serviceFormSuccessModal } } = useSiteVariables();
const serviceFormSuccessModalIsOpen = ref(serviceFormSuccessModal);

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMd = breakpoints.smallerOrEqual('md');
const buttonSize = computed(() => (isMd.value ? 'medium' : 'large'));

function isAddressInput(inputName: string, value: unknown): value is AddressValues {
  return inputName === 'address' && typeof value === 'object' && !!((value as AddressValues).route);
}

function addToGroupedData(groupedData: GroupedData, kind: string, entry: GroupedDataEntry) {
  if (!entry.value) return;

  if (!groupedData[kind]) {
    groupedData[kind] = [];
  }

  groupedData[kind].push(entry);
  groupedData[kind].sort((a, b) => a.order - b.order);
}

function getTranslatedNameAndOrder(inputName: string, kind: string): { translatedName: string; order: number } {
  const input = props.service.inputs?.find(i => i.name === inputName);

  const translatedName =
    input && input.customName ? input.customName : t(`serviceAssistance.success.labels.${kind}.${inputName}`);
  const order = input?.order || 0;

  return { translatedName, order };
}

function translateAndGroupFormData(formValues: Values) {
  const groupedFormData: GroupedData = {};

  Object.entries(formValues).forEach(([inputName, value]) => {
    const [name, kind] = inputName.split('-');
    const finalValue = isAddressInput(name, value) ? value.route : value;
    const { translatedName, order } = getTranslatedNameAndOrder(name, kind);
    addToGroupedData(groupedFormData, kind, { inputName: translatedName, value: finalValue, order });
  });

  return groupedFormData;
}

const formDataByKind = computed(() => translateAndGroupFormData(props.formValues));

</script>

<template>
  <div class="flex flex-col items-center px-6 py-8">
    <h1 class="mb-3 text-center text-2xl font-medium md:text-3xl">
      {{ $t('serviceAssistance.success.title') }}
    </h1>

    <h2 class="text-center text-lg font-normal text-gray-500 md:text-xl">
      {{ $t('serviceAssistance.success.description') }}
    </h2>

    <div class="w-full max-w-xl pt-4">
      <div class="rounded-2xl border border-gray-200 bg-white py-9">
        <div class="mb-6 flex w-full justify-between border-b border-gray-200 px-8 pb-6 md:pb-8">
          <p class="text-right text-lg md:text-xl">
            {{ service.name }}
          </p>
        </div>
        <div
          v-for="(inputs, groupName) in formDataByKind"
          :key="groupName"
          class="flex w-full flex-col px-8"
        >
          <p class="pb-2 text-xs font-bold text-primary-700 sm:text-sm">
            {{ $t(`serviceForm.${groupName}.title`) }}
          </p>
          <div
            v-for="input in inputs"
            :key="input.inputName"
          >
            <div class="flex justify-between pb-3 text-xs font-normal text-gray-700 sm:text-sm">
              <p> {{ input.inputName }}</p>
              <p> {{ input.value }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full gap-3 py-3">
        <mok-button
          type="button"
          variant="secondary"
          :label="$t('serviceForm.backHome') "
          :size="buttonSize"
          href="/"
          full-width
          data-testid="back-home-button"
        />
        <mok-button
          v-if="serviceHistory"
          type="button"
          variant="primary"
          :label="$t('serviceForm.seeTracking') "
          :size="buttonSize"
          :href="`/user/services_history/${otId ?? ''}`"
          full-width
          data-testid="see-tracking-button"
        />
      </div>
    </div>
  </div>
  <mok-alert-modal
    v-if="serviceFormSuccessModal"
    :title="$t('serviceAssistance.success.modal.title')"
    :confirm-label="$t('serviceAssistance.success.modal.button')"
    :icon="PhPhoneCall"
    :is-open="serviceFormSuccessModalIsOpen"
    @close="serviceFormSuccessModalIsOpen = false"
    @confirm="serviceFormSuccessModalIsOpen = false"
  >
    <template #description>
      <p class="text-gray-700">
        {{ $t('serviceAssistance.success.modal.description') }}
      </p>
    </template>
  </mok-alert-modal>
</template>
