<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhPhone, PhHouse, PhEnvelope, PhInstagramLogo } from '@phosphor-icons/vue';
import { computed } from 'vue';

import { Color } from '@/types/color';
import type { FourthFooter } from '@/types/fourth-footer';
import type { LegalDocument } from '@/types/legal-document';
import FacebookIcon from '@assets/images/facebook.svg';

import LegalDocumentsLink from '../../legal-documents-links.vue';

interface Props {
  fourthFooter: FourthFooter;
  legalDocuments: LegalDocument[];
  copyrightText: string;
  copyrightYear: string;
}

const colorStyles = {
  primary: {
    bg: 'bg-primary',
    text: 'text-primary',
    iconVariant: 'primary',
  },
  secondary: {
    bg: 'bg-secondary',
    text: 'text-secondary',
    iconVariant: 'secondary',
  },
  black: {
    bg: 'bg-black',
    text: 'text-black',
    iconVariant: 'gray',
  },
  white: {
    bg: 'bg-white',
    text: 'text-white',
    iconVariant: 'white',
  },
};

const props = withDefaults(
  defineProps<Props>(),
  {
    legalDocuments: () => [] as LegalDocument[],
    copyrightText: '',
    copyrightYear: '',
  },
);

const extendedBgColorVariant = computed(() => props.fourthFooter.extendedBgColor || 'white');
const textColorVariant = computed(() => props.fourthFooter.textColor || 'black');
const iconColorVariant = computed(() => props.fourthFooter.iconColor || 'primary');
const extendedBgColorClass = computed(() => colorStyles[extendedBgColorVariant.value].bg);
const textColorClass = computed(() => colorStyles[textColorVariant.value].text);
const iconColorClass = computed(() =>
colorStyles[iconColorVariant.value].iconVariant as Color);

const logos = computed(() => {
  const validLogos = [
    { url: props.fourthFooter.firstLogoUrl, webpUrl: props.fourthFooter.firstLogoWebpUrl,
      blurhash: props.fourthFooter.firstLogoBlurhash },
    { url: props.fourthFooter.secondLogoUrl, webpUrl: props.fourthFooter.secondLogoWebpUrl,
      blurhash: props.fourthFooter.secondLogoBlurhash },
    { url: props.fourthFooter.thirdLogoUrl, webpUrl: props.fourthFooter.thirdLogoWebpUrl,
      blurhash: props.fourthFooter.thirdLogoBlurhash },
    { url: props.fourthFooter.fourthLogoUrl, webpUrl: props.fourthFooter.fourthLogoWebpUrl,
      blurhash: props.fourthFooter.fourthLogoBlurhash },
    { url: props.fourthFooter.fifthLogoUrl, webpUrl: props.fourthFooter.fifthLogoWebpUrl,
      blurhash: props.fourthFooter.fifthLogoBlurhash },
  ].filter(logo => logo.url);

  return validLogos.map((logo, index) => ({
    ...logo,
    showSeparator: index < validLogos.length - 1,
  }));
});
const hasValidLogos = computed(() => logos.value.some(logo => logo.url));

const socialButtons = computed(() => [
  {
    url: props.fourthFooter.sponsorHomeLink,
    icon: PhHouse,
    condition: !!props.fourthFooter.sponsorHomeLink,
  },
  {
    url: `mailto:${props.fourthFooter.contactEmail}`,
    icon: PhEnvelope,
    condition: !!props.fourthFooter.contactEmail,
  },
  {
    url: props.fourthFooter.facebookLink,
    icon: FacebookIcon,
    condition: !!props.fourthFooter.facebookLink,
  },
  {
    url: props.fourthFooter.instagramLink,
    icon: PhInstagramLogo,
    condition: !!props.fourthFooter.instagramLink,
  },
].filter(button => button.condition));

</script>

<template>
  <div
    class="w-full"
    :class="[extendedBgColorClass, textColorClass]"
  >
    <div class="mx-auto flex w-full flex-col gap-7 px-6 py-4 sm:flex-row sm:justify-between sm:gap-0 sm:px-20 sm:py-8 md:max-w-screen-2xl">
      <div class="flex flex-row justify-between sm:flex-col sm:gap-0">
        <div class="flex flex-col items-center justify-center gap-4 sm:mb-6 sm:flex-row sm:flex-wrap sm:items-start sm:justify-start sm:gap-6 sm:gap-x-10">
          <template v-if="!hasValidLogos">
            <img
              class="h-4 w-full max-w-20 overflow-hidden object-contain sm:h-8"
              :alt="$t('common.logo')"
              :src="props.fourthFooter.darkBgLogoUrl"
            >
          </template>
          <template
            v-for="(logo) in logos"
            v-else
            :key="logo.url"
          >
            <base-image
              v-if="logo.url"
              class="h-10 max-w-fit overflow-hidden object-contain sm:h-16"
              :img-styles="'object-contain'"
              :src="logo.url"
              :webp-src="logo.webpUrl"
              :blurhash="logo.blurhash"
            />
            <div
              v-if="logo.showSeparator"
              class="hidden h-16 w-0.5 border-l border-gray-200 sm:block"
            />
          </template>
        </div>
        <div class="mt-auto hidden flex-row sm:block">
          <legal-documents-link
            :legal-documents="legalDocuments"
            :copyright-text="copyrightText"
            :copyright-year="copyrightYear"
            :text-color-class="textColorClass"
            class="flex min-w-0 flex-wrap gap-x-6 text-xs"
            data-testid="legal-documents-link"
          />
        </div>
        <div class="mr-0 mt-auto w-max flex-col items-end justify-end sm:hidden">
          <div class="flex flex-row gap-3">
            <mok-button
              v-for="(button) in socialButtons"
              :key="button.url"
              :href="button.url"
              :color="iconColorClass"
              :icon="button.icon"
              variant="link"
              rounded-full
              target="_blank"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-row sm:hidden"
      >
        <div class="w-full">
          <legal-documents-link
            :legal-documents="legalDocuments"
            :copyright-text="copyrightText"
            :copyright-year="copyrightYear"
            :text-color-class="textColorClass"
            class="flex flex-col text-xs"
            data-testid="legal-documents-link"
          />
        </div>
        <div
          class="flex w-full justify-end self-end text-base"
        >
          <div class="flex flex-col">
            <div
              v-if="fourthFooter.contactPhone"
              class="flex flex-col py-0"
            >
              <div class="flex flex-row justify-start sm:items-center">
                <PhPhone
                  weight="fill"
                  class="mr-1 flex size-4 first-letter:fill-current sm:mr-4 sm:size-8 sm:items-center"
                  :class="[textColorClass]"
                />
                <div class="flex flex-col items-center text-xs font-medium sm:font-bold">
                  <p>
                    {{ fourthFooter.contactPhone }}
                  </p>
                </div>
              </div>
            </div>
            <base-image
              v-if="fourthFooter.regulatoryComplianceImageUrl"
              class="mt-4 h-5 max-w-sm justify-end self-end overflow-hidden object-contain"
              :src="fourthFooter.regulatoryComplianceImageUrl"
              :webp-src="fourthFooter.regulatoryComplianceWebpImageUrl"
              :blurhash="fourthFooter.regulatoryComplianceImageBlurhash"
            />
          </div>
        </div>
      </div>
      <div class="hidden flex-col items-end sm:flex">
        <p class="shrink-0 flex-row text-xs">
          {{ $t('sections.footers.fourthFooter.contactPhoneText') }}
          <span class="font-semibold">{{ fourthFooter.contactPhone }}</span>
        </p>
        <div class="mt-4 flex flex-row gap-6">
          <mok-button
            v-for="(button) in socialButtons"
            :key="button.url"
            :href="button.url"
            :color="iconColorClass"
            :icon="button.icon"
            variant="link"
            size="large"
            rounded-full
            target="_blank"
          />
        </div>
        <base-image
          v-if="fourthFooter.regulatoryComplianceImageUrl"
          class="mt-5 h-14 max-w-sm justify-end self-end"
          :src="fourthFooter.regulatoryComplianceImageUrl"
          :webp-src="fourthFooter.regulatoryComplianceWebpImageUrl"
          :blurhash="fourthFooter.regulatoryComplianceImageBlurhash"
          img-styles="object-contain"
        />
      </div>
    </div>
  </div>
</template>
